
// --------------------------------------------------------------------------------------------------------------------
// Text Color Definitions
// --------------------------------------------------------------------------------------------------------------------

.t-primary {
  color: @brand-primary;
  p {
    color: inherit;
  }
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
  i.fa, i.b-icon {
    color: inherit;
  }
}

p, h1, h2, h3, h4, h5, h6, i.fa ,i.b-icon {
  &.t-primary {
    color: @brand-primary;
  }
}

.t-secondary {
  color: @brand-secondary;
  p {
    color: inherit;
  }
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
  i.fa, i.b-icon {
    color: inherit;
  }
}

p, h1, h2, h3, h4, h5, h6, i.fa ,i.b-icon {
  &.t-secondary {
    color: @brand-secondary;
  }
}

.t-tertiary {
  color: @brand-tertiary;
  p {
    color: inherit;
  }
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
  i.fa, i.b-icon {
    color: inherit;
  }
}

p, h1, h2, h3, h4, h5, h6, i.fa ,i.b-icon {
  &.t-tertiary {
    color: @brand-tertiary;
  }
}
