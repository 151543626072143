footer {

  display: block;
  float: left;
  position: relative;
  width: 100%;
  @media ( min-width: @screen-lg-min) {
    .logo-container {
      //width: auto;
    }
  }

  @media ( max-width: @screen-md-min) {
    .logo-container {
      text-align: center;
      float: left;
      width: 100%;
    }
  }

  img.inline-logo {
    width: 100%;
    max-width: 250px;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
  }

  ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
    li {

      a {
        //font-family: @headings-font-family;
        //font-weight: @headings-font-weight;
        margin-bottom: .5em;
      }

      li {
        margin-bottom: .5em;
        padding-left: 1em;
        a {
          margin-bottom: 0px;
          font-size: .75em;
          font-family: @font-family-base;
          font-weight: @font-weight-base;
          //color: @brand-text-on-dark;
        }
          &:before {
          margin-left: -1em;
          content: '\25A0';
          //color: @brand-secondary !important;
          display: inline-block;
          width: 1em;
          margin-top: -0.125em;
          float: left;
        }
        font-family: @font-family-base;
        padding-left: 1em;
        li {
          padding-left: 2em;
        }
      }
      a {

        //color: @brand-text-on-dark !important;
        display: inline-block;
        width: 100%;
        padding: 0em;
        &:hover {
          //color: @brand-gray-light !important;
          text-decoration: none;
        }
      }
      ul {
        margin-bottom: 0px;
        font-size: 1em;
        text-transform: none;
        li {
          font-family: @font-family-base;
          font-weight: @font-weight-base;
          margin-bottom: 0em;
          a {
            font-weight: inherit;;
          }
        }
      }
    }
  }

  @media ( max-width: @screen-sm-min){
    ul{
      justify-content: center;
    }
  }

  .row {
    @media(max-width: @screen-md-min){
      padding-bottom: 2em;
      //text-align: center;
      h1,h2,h3,h4,ul {
        text-align:left;
      }
      &.footer-widget-area {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        > div {
          width :33%;
        }
      }
    }
    @media(max-width: @screen-sm-min){


      &.footer-widget-area {
        > div {
          margin-bottom: 1em;
          width :300px;
        }
      }
    }
  }
  .after-footer {
    ul.menu {
      display: flex;
      align-items:center;
      flex-wrap: wrap;
      li {
        width: auto;
        padding: 0 0 0 0;
        a {
          padding: .25em .5em;
          margin: 0 0 0 0;

        }
      }
    }
  }
}
