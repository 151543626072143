
.border-class-mixin(@screen-min,@name,@border-axis) {
  @spec: ~"@{name}";
  @axis: ~"@{border-axis}";
  // No Wrapping Solutions
  .border-@{spec}-@{axis}{
    @media (min-width: @screen-min) {
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
      border-left-width: 0;
      border-@{axis}-width: @border-column-width;
      border-color: #9db1bd;
      border-style: @border-column-style;
    }
  }
}
.border-class-mixin(0,~'xs','top');
.border-class-mixin(0,~'xs','right');
.border-class-mixin(0,~'xs','left');
.border-class-mixin(0,~'xs','bottom');
.border-class-mixin(0,~'xs','none');

.border-class-mixin(@screen-sm-min,~'sm','top');
.border-class-mixin(@screen-sm-min,~'sm','right');
.border-class-mixin(@screen-sm-min,~'sm','bottom');
.border-class-mixin(@screen-sm-min,~'sm','left');
.border-class-mixin(@screen-sm-min,~'sm','none');

.border-class-mixin(@screen-md-min,~'md','top');
.border-class-mixin(@screen-md-min,~'md','right');
.border-class-mixin(@screen-md-min,~'md','bottom');
.border-class-mixin(@screen-md-min,~'md','left');
.border-class-mixin(@screen-md-min,~'md','none');

.border-class-mixin(@screen-lg-min,~'lg','top');
.border-class-mixin(@screen-lg-min,~'lg','right');
.border-class-mixin(@screen-lg-min,~'lg','bottom');
.border-class-mixin(@screen-lg-min,~'lg','left');
.border-class-mixin(@screen-lg-min,~'lg','none');

