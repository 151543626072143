


#scrollLockModal {
  display: block;
  position: fixed;
  width: 100%;
  height: 0;
  z-index: 300;
  top: 0;
  left: 0;
  background: #fff;
  opacity: .0;
  cursor: pointer;
  visibility: hidden;
  transition: opacity 0.3s;
  transition-timing-function: ease-in-out;
  &.scrollLock {
    opacity: .35;
    height: 100%;

    visibility: visible;
  }
}

#cta-modal {
  box-shadow: 0px 4px 4px 3px fade(@brand-gray-dark, 25);
  @media (max-width: @screen-sm-max) {
    width: 100%;
  }

  // ACTUAL MODAL PROPERTIES -------------------------
  position: fixed;
  max-height: 100%;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: block;
  top: -500000%;
  left: 50%;
  transform: translate(-50%) translateY(-50%) scale(0, 0);
  z-index: 301;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: transform 0.3s;
  transition-timing-function: ease-in-out;
  background: #fff;
  &.open {
    visibility: visible;
    top: 50%;
    transform: translate(-50%) translateY(-50%) scale(1, 1);
    display: block;

  }

  #closeModal {

    font-size: 1em;
    //background: @brand-secondary;
    display: block;
    position: absolute;
    top: -0px;
    right: -0px;
    padding: 0.6em 0.8em;
    z-index: 22;

    text-align: center;
    background: @brand-black;
    color: #fff;

    i {
      width: 1em;
      height: 1em;
      color: inherit;
    }
  }
}

#cta-modal {
  .row.content-row-cta-modal {
    @media (min-width: @screen-xs-max) {
      display: flex;
      align-items: stretch;
    }
    .column.modal-live-area-column {
    }
  }
  @media (max-width: @screen-xs-max) {
    width: ~'calc(100% - 40px)';
  }
  .row.content-row-cta-modal {
    .column {
      padding-top: @grid-gutter-width;
      padding-bottom: @grid-gutter-width;
    }
  }
}

#cta-modal {
  background: @brand-text-on-dark;
  form {
    div.validation_error {
      display: none !important;
    }
  }
  form .gform_body {

    ul.gform_fields {
      span.gform_wrapper.gfield_required {
        color: @brand-tertiary !important;
      }
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      label { color: @brand-black;}
      li.name-field {
        width: 25%;
      }
      li.phone-field {
        width: 25%;
      }
      li.email-field {
        width: 25%;
      }
      li.company {
        width: 25%;
      }
      li.subject {
        width: 100%;
      }
      li.dropdown-field {
        width: 100%;
      }
      li.message-field {
        width: 100%;
      }

      li.name-field,
      li.phone-field,
      li.email-field,
      li.dropdown-field,
      li.message-field,li.company,li.subject {
        input, textarea, select {
          width: 100%;
          background: @brand-text-on-dark;
          color: @brand-black;
          border: 1px solid @brand-black !important;
          &:focus {
            outline: none !important;


          }
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: @brand-black;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: @brand-black;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: @brand-black;

          }
        }
      }
      @media (max-width: @screen-sm-max) {


        li.message-field {
          width: 100%;
        }

        li.name-field {
          width: 50%;
        }
        li.phone-field {
          width: 50%;
        }
        li.email-field {
          width: 50%;
        }
        li.company {
          width: 50%;
        }
        li.subject {
          width: 100%;
        }
      }
      @media (max-width: @screen-xs-max) {
        li.name-field {
          width: 100%;
        }
        li.phone-field {
          width: 100%;
        }
        li.email-field {
          width: 100%;
        }
        li.company {
          width: 100%;
        }
        li.subject {
          width: 100%;
        }
        li.name-field {
          width: 100%;
        }

        li.phone-field {
          width: 100%;
        }

        li.email-field {
          width: 100%;
        }

        li.dropdown-field {
          width: 100%;
        }

        li.message-field {
          width: 100%;
        }
      }
    }
  }
}

#cta-modal {
  h2.modal-title {
  }
  #cta-logo {
    display: inline-block;
    max-width: 320px;
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
    img {
      width: 100%;
      height: auto;
    }
  }
  .address-phone {
    font-size: 14px;
    a {
      box-sizing:border-box;
      padding-left: 1.5em;
      font-weight: 400;
      color: #fff;
      width:100%;
      display: inline-block;
      span {
        display: inline-block;
        width: 100%;
      }
      i {
        margin-top: .6em;
        display: inline-block;
        float: left;
        margin-left: -1.5em;
      }
    }
  }
  .gform_wrapper .gform_footer .gform_button.button {
    @media(min-width: @screen-md-min) {
      //margin-left: 14px;
    }
    background: @brand-primary;
    color: @brand-gray-dark;
    &:hover {
      color: @brand-primary;
      border-color: @brand-primary;
      background: @brand-gray-dark;
    }
  }
}

