#content {
  .featured-image-article {
    width: 100%;
    display: inline-block;
    img {
      margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);
    }
  }
  .feed-content {
    margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);
  }
}

.feed-header {
  margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);
}
.feed-article {
  margin: 0 0 (@grid-gutter-width );
}