
#live-area {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    line-height: @headings-line-height;
    text-transform: @heading-text-transform;
    margin-top: @headings-vertical-adjust;

    &.text-xl, &.text-lg, &.text-xxl {
      line-height: @headings-line-height;
    }
  }
}

strong {
  font-weight: @strong-weight;

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    font-weight: @strong-headings-weight;
  }

}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  span {
    font-size: 0.5em;
    display: block;
    line-height: @headings-line-height;
    font-weight: 400;
    width: 100%;
    margin-top: .125em;
  }

  strong {
    color: @brand-primary;
    font-weight: @strong-headings-weight;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  //text-transform: Uppercase;
  letter-spacing: @letter-spacing-correction;
  margin-top: 0;
  margin-bottom: (@grid-gutter-width * @heading-margin-factor);
  margin-left: @tex-indent-correction;
  width: 100%;
  //display: inline-block;
  //float: left;
  &:only-child {
    margin-bottom: 0;
  }

  //&:last-child {
  //  margin-bottom: 0px;
  //}
}

.text-object {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-bottom: ((@grid-gutter-width * @heading-margin-factor));

    &:only-child {
      margin-bottom: ((@grid-gutter-width * @heading-margin-factor) );
    }

  }

  &:only-child {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
      margin-bottom: ((@grid-gutter-width * @heading-margin-factor) );

      &:only-child {
        margin-bottom: ((@grid-gutter-width * @heading-margin-factor));
      }

    }
  }

}

.column {
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

p {
  + h1, + .h1,
  + h2, + .h2,
  + h3, + .h3,
  + h4, + .h4,
  + h5, + .h5,
  + h6, + .h6 {
    //margin-top: (@grid-gutter-width * @paragraph-margin-factor);
  }
}

p {
  display: inline-block;
  width: 100%;
  //float: left;
  margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);

  &:empty {
    display: none !important;
  }

  + ul, + ol {
    //margin-top: (@grid-gutter-width / @paragraph-margin-factor * -.5);
  }
}

.pt-image {
  margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);

  &:last-child {
    margin-bottom: 0;
  }
}

#live-area p {
  + ul, + ol {
    //margin-top: (@grid-gutter-width / @paragraph-margin-factor * -.5);
  }
}

div.text-object:last-child {
  ul, li, p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    &:last-child {
      margin-bottom: 0;
    }
  }
}


#live-area, #content {
  .pt-image {
    margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }

    img {
      max-width: 100%;
      width: auto;
    }

    &.img-inline {
      img {
        display: inline-block;
      }
    }

    &.img-cover img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
      max-width: none;
    }

    &.img-contain img {
      object-fit: contain;
      object-position: center center;
      width: 100%;
      height: 100%;
      max-width: none;
    }

    &.img-icon-sm img {
      display: inline-block;
      height: 100px;
      width: auto;
    }

    &.img-icon-md img {
      display: inline-block;
      height: 240px;
      width: auto;
    }

    &.img-icon-lg img {
      display: inline-block;
      height: 360px;
      width: auto;
    }
  }

  img {
    &.img-cover {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
      max-width: none;
    }

    &.img-contain {
      object-fit: contain;
      object-position: center center;
      width: 100%;
      height: 100%;
      max-width: none;
    }

    &.img-icon-sm {
      width: 120px;
      display: inline-block;
    }

    &.img-icon-md {
      width: 240px;
      display: inline-block;
    }

    &.img-icon-lg {
      width: 360px;
      display: inline-block;
    }
  }
}

//div.text-object {
//  + .btn {
//    margin-top: (@grid-gutter-width * @paragraph-margin-factor);
//  }
//}

i.fa, i.b-icon, span.b-icon {
  //color: @brand-primary;
}

a {
  i.fa, i.b-icon, span.b-icon {
    margin-bottom: 0px;
    margin-right: .25em;
  }
}

i.fa, .b-icon {
  margin-bottom: (@grid-gutter-width * @heading-margin-factor);

  &.h-icon {
    margin-bottom: 0;
    margin-right: .25em;
    vertical-align: bottom;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.btn {
  i, span.b-icon {
    color: inherit;
  }
}

.btn {
  margin-bottom: (@grid-gutter-width * @paragraph-margin-factor );
  font-weight: @btn-font-weight;

  &:last-child {
    margin-bottom: 0;
  }

}

#live-area {
  .text-object {
    ul, ol {
      font-size: @font-size-base;
      line-height: @line-height-base;
      //display: inline-block;
      padding: 0 0 0 0;
      width: 100%;
      //float: left;
      margin: 0 0 0 0;
      list-style: none;

      ul, ol {
        margin-bottom: 0;
      }

      li {
        //display: inline-block;
        width: 100%;
        padding-left: 1.5em;
        //padding-top: .5em;
        padding-bottom: (@grid-gutter-width * @paragraph-margin-factor / 3);
        border-bottom-width: @border-column-width;

        &:last-child {
          border-bottom: 0px solid transparent;
        }


        margin-bottom: (@grid-gutter-width * @paragraph-margin-factor / 3);

        li {
          margin-bottom: (@grid-gutter-width * @paragraph-margin-factor / 3);

          &:last-child {
            margin-bottom: 0;
          }

          li {
            margin-bottom: (@grid-gutter-width * @paragraph-margin-factor / 3);

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &:before {

          font-weight: bold;
          display: inline-block;
          min-width: 1.5em;
          width: auto;
          padding-right: 1em;
          margin-left: -.75em;
          color: @brand-primary;
        }
      }
    }

    ul {
      li {
        &:before {
          content: '+';
        }
      }
    }

    ol {
      counter-reset: section;
      list-style-type: none;

      li {
        &:before {

          counter-increment: section;
          content: counters(section, ".");
        }
      }
    }

  }
}


#live-area {
  ul, ol {
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor )
  }
}

blockquote {
  padding: (@line-height-computed / 2) @line-height-computed;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border-left: 5px solid @blockquote-border-color;

  p {
    &:last-child {
      font-size: .75em;
      font-style: italic;

      &:only-child {
        font-size: 1em;
        font-style: normal;
      }
    }
  }
}

hr {
  width: 100%;
  margin-top: ( @grid-gutter-width * @paragraph-margin-factor );
  margin-bottom: ( @grid-gutter-width * @paragraph-margin-factor );
  border: 0;
  border-top: @border-column-width solid @default-border-color;
}

column > hr, .text-object:first-child > hr, .text-object:last-child > hr {
  &:only-child {
    margin: 0 0 0 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

p, h1, h2, h3, h4, h5, h6, ul, ol, div.text-object {
  + hr {
    margin-top: 0;
  }
}

iframe {
  max-width: 100%;
  width: 100%;
  display: inline-block;
}

.next-prev-links {
  border-top: 1px solid @text-color;

  a.btn {
    margin: 20px 0 20px 0;
    display: inline-block;
  }

}

// --------------------------------------------------------------------------------------------------------------------
// Type and heading Size Adjustments
// --------------------------------------------------------------------------------------------------------------------

.size-iterator(@n, @i: 2) when (@i =< @n) {
  .text-@{i}x {
    font-size: unit(@i, em);
    line-height: @line-height-base;
    .headings-line-height-reset(1);
  }
  .size-iterator(@n, (@i + 1));
}

.size-iterator(5);

///////////////////////// ------------------------------------------------------------------------------------

.headings-line-height-reset(@size-factor) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    line-height: @headings-line-height;

    &.text-xl, &.text-xxl, &.text-lg {
      line-height: @headings-line-height;
    }

  }
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-bottom: ((@grid-gutter-width * @heading-margin-factor) * @size-factor);

    &:only-child {
      margin-bottom: 0;
    }

  }

  .text-object {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
      margin-bottom: ((@grid-gutter-width * @heading-margin-factor) * @size-factor);

      &:only-child {
        margin-bottom: ((@grid-gutter-width * @heading-margin-factor) * @size-factor);
      }

    }

    &:only-child {
      h1, .h1,
      h2, .h2,
      h3, .h3,
      h4, .h4,
      h5, .h5,
      h6, .h6 {
        margin-bottom: ((@grid-gutter-width * @heading-margin-factor) * @size-factor);

        &:only-child {
          margin-bottom: 0;
        }

      }
    }
  }
  p {
    + h1, + .h1,
    + h2, + .h2,
    + h3, + .h3,
    + h4, + .h4,
    + h5, + .h5,
    + h6, + .h6 {
      //margin-top: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);
    }

    + ul, + ol {
      //margin-top: (@grid-gutter-width / @paragraph-margin-factor * @size-factor * -.5 );
    }
  }
  ul, ol {
    li {
      margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor / 3) * @size-factor);

      li {
        margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor / 3) * @size-factor);

        &:last-child {
          margin-bottom: 0;
        }

        li {
          margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor / 3) * @size-factor);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .btn {
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor );

    &:last-child {
      margin-bottom: 0px;
    }
  }
  p {
    margin: 0 0 ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);

    &:empty {
      display: none !important;
    }
  }
  i.fa, .b-icon {
    margin-bottom: ((@grid-gutter-width * @heading-margin-factor) * @size-factor);

    &.h-icon {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .pt-image {
    margin: 0 0 ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);

    &:last-child {
      margin-bottom: 0;
    }
  }
  #live-area {
    ul, ol {
      margin: 0 0 ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);

      ul, ol {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  hr {
    margin-top: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);
    margin-bottom: ((@grid-gutter-width * @paragraph-margin-factor) * @size-factor);
    border: 0;
    border-top: @border-column-width solid @text-color;
  }

  p, h1, h2, h3, h4, h5, h6, ul, ol {
    + hr {
      margin-top: 0;
    }
  }

}

.text-lg {
  @size: @text-large;
  @local-size: unit(@size, em);
  .headings-line-height-reset(@text-large);
  font-size: @local-size;
  line-height: (@line-height-base);

  @media (max-width: @screen-md-max) {
    @size: (@text-large );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }
  @media (max-width: @screen-sm-max) {
    @size: (@text-large - 0.125 );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-xs-max) {
    @size: (@text-large - 0.125 - 0.125 );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }
}

.text-xl {
  @size: @text-xlarge;
  @local-size: unit(@size, em);
  .headings-line-height-reset(@text-xlarge);
  font-size: @local-size;
  line-height: (@line-height-base);
  @media (max-width: @screen-md-max) {
    @size: (@text-xlarge - 0.125);
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-sm-max) {
    @size: (@text-xlarge - 0.125 - 0.125);
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-xs-max) {
    @size: (@text-xlarge - 0.125 - 0.125 - 0.125);
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

}

.text-xxl {
  @size: @text-xxlarge;
  @local-size: unit(@size, em);
  .headings-line-height-reset(@text-xlarge);
  font-size: @local-size;
  line-height: (@line-height-base);
  @media (max-width: @screen-md-max) {
    @size: (@text-xxlarge - .25);
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-sm-max) {
    @size: (@text-xxlarge - .25  - .125 );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

  @media (max-width: @screen-xs-max) {
    @size: (@text-xxlarge  - .25  - .25  - .125 );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }
  @media (max-width: @screen-xs) {
    @size: (@text-xxlarge  - .25  - .25  - .25  );
    @local-size: unit(@size, em);
    font-size: @local-size;
    .headings-line-height-reset(@size);
  }

}

.heading-h1 {
  font-size: @font-size-h1;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.heading-h2 {
  font-size: @font-size-h2;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.heading-h3 {
  font-size: @font-size-h3;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.heading-h4 {
  font-size: @font-size-h4;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.heading-h5 {
  font-size: @font-size-h5;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.heading-h6 {
  font-size: @font-size-h6;
  line-height: @headings-line-height;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }
}

.text-reset {
  font-size: @base-em-size;
  line-height: @line-height-base;

  i.fa, i.b-icon {
    font-size: inherit;
    line-height: inherit;
  }

  .headings-line-height-reset(1);
}

#live-area, #content {
  section .row.icon-block {
    .pt-image {
      width: 100%;
      text-align: inherit;

      img {
        display: inline-block;
        max-width: (@grid-gutter-width * 3);
      }
    }
  }

  section .row.logos-row {
    .column {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    .pt-image {
      box-sizing: border-box;
      padding: (@grid-gutter-width/2) (@grid-gutter-width/4);
      width: 220px;
      margin-bottom: 0px;

      img {
        margin-bottom: 0px;
      }
    }
  }
}


@keyframes colorchange {
  0% {
    background: #FF009A;
  }
  25% {
    background: #8057CD;
  }
  50% {
    background: #00ADEE;
  }
  75% {
    background: #8057CD;
  }
  100% {
    background: #FF009A;
  }
}

.note {
  padding: 1rem;
  color: #fff;
  font-size: 1rem !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  animation: colorchange 10s infinite;
  margin-bottom: 1rem;
}

.note p:before {
  content: "[ ";
}

.note p:after {
  content: " ]";
}

.note {
  display: none !important;

  *:last-child {
    margin-bottom: 0 !important;
  }
}

body.logged-in.role-administrator {
  span.highlighted-copy {
    display: inline-block;
    background: yellow;
    color: black;
  }
  .note {
    width: 100%;
    display: inline-block !important;
  }
}


#live-area section .row .column .text-object {
  &.quad-list {
    ul {
      display: flex;
      .bs-flex-fix();
      align-items: stretch;
      flex-wrap: wrap;
    }

    @media (max-width: 480px) {
      li {
        width: 100%;
        padding-right: 1em;
        display: inline-block;
      }
    }
    @media (max-width: @screen-sm-min) {
      li {
        width: 50%;
        padding-right: 1em;
        display: inline-block;
      }
    }
    @media (min-width: @screen-sm-min) {
      li {
        width: 33.333%;
        padding-right: 1em;
        display: inline-block;
      }
    }
    @media (min-width: @screen-md-min) {
      li {
        width: 25%;
        padding-right: 1em;
        display: inline-block;
      }
    }
  }

  &.checkmark-list {
    ul li {
      &:before {
        content: "\f00c";
        padding-right: 0.4em;
        color: @brand-primary;
        font-family: FontAwesome;
      }
    }

  }

  &.map-list {
    ul li {
      &:before {
        content: "\f041";
        padding-right: 0.4em;
        color: @brand-primary;
        font-family: FontAwesome;
      }
    }

  }
}
.text-objects(@color;){
  h1, h2, h3, h4, h5, h6, blockquote, address, li, span, strong, p {
    color: @color;
  }
}
.text-colors() {
  &.text-muted, .text-muted {
    color: @text-muted;
    .text-objects(@text-muted);
  }
  &.text-primary, .text-primary {
    color: @brand-primary;
    .text-objects(@brand-primary);
  }
  &.text-secondary, .text-secondary {
    color: @brand-secondary;
    .text-objects(@brand-secondary);
  }
  &.text-tertiary, .text-tertiary {
    color: @brand-tertiary;
    .text-objects(@brand-tertiary);
  }

}
#live-area {
  .text-colors();
  h1, h2, h3, h4, h5, h6, blockquote, address, li, span, strong, p {
    .text-colors();
  }
}
