// GRAVITY FORMS
@form-border-radius: 0em;
@form-border-width: 1px;
@form-border-style: solid;
@form-padding-horz: 1em;
@form-padding-vert: .25em;
#live-area .gform_wrapper.gform_fields {
  grid-column-gap: (@grid-gutter-width/2);
  grid-row-gap: (@grid-gutter-width/2);
  li {
    padding-left: 1em;
    padding-right: 1em;
    &:before {

      display: none;
    }
  }
}
.container,.container-fluid {
  .gform_wrapper.gravity-theme {
    label, legend {
      font-family: @headings-font-family;
      font-weight: @headings-font-weight;
      font-size: 1.125em;
    }
    .gchoice, .ginput_container_consent, .ginput_complex  {
      label, legend {
        font-weight: @font-weight-base;
        font-family: @font-family-base;
        font-size: @font-size-base;
      }
    }
    .ginput_complex {
      label, legend {
        font-size: (@font-size-base * .75);
      }
    }
    input[type=color],
    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
      border: @form-border-width @form-border-style;
      border-radius: @form-border-radius;
      padding: @form-padding-vert @form-padding-horz @form-padding-vert @form-padding-horz;
      font-size: @font-size-base;
      line-height: @line-height-base;
      font-weight: @font-weight-base;
      color: @brand-black;
      &:focus {
        outline: 0;
        box-shadow: 0px 0px 0px 2px @brand-primary;
      }
    }
  }
  .gform_button.button {
    .btn();
    .btn-default();
    color: @brand-secondary !important;
  }
}
