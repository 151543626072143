.social-links {
  float:right;
  text-align:right;
  //font-size: 1em;
  @media( max-width:@screen-sm-max) { text-align:left; margin-top:1em;}
  @media( max-width:@screen-xs-max) { text-align:center; margin-top:1em;}
  .social-link {
    font-size:1em;
    //font-size: 16px;
    height: auto;
    width: auto;
    padding: .4em .675em;
    //line-height:1em;
    text-align:center;
    display:inline-block;
    margin-left:0em;
    margin-right:0em;
    
    background: inherit;
    position:relative;

    i {
      font-size:1em;
      width:1em;
      height:1em;
      color: inherit;;
    }
    &:hover{
      i {
        color: inherit;
      }
    }
  }
}