
.button-variant-alternate(@color; @background; @border;) {
  color: inherit;
  padding: (@btn-padding-base-vertical * 0);
  background-color:transparent;
  border: 0 solid fade(@background,0%);
  border-bottom: @btn-alt-thickness solid fade(@background,0%);
  transition: background @transition-time-global, border @transition-time-global;
  border-radius: 0;
  text-decoration: underline;
  text-decoration-color: @background;
  &:active,
  &.active,&:focus,
  &.focus ,&:hover,&:hover {
    text-decoration-thickness: .125em;
    background: transparent;
    color: inherit;
    border-bottom: @btn-alt-thickness solid fade(@background,100%);

  }
}


