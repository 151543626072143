// Global template background fixes.
section {
  background-size: cover;
}

// fixes section background default.
// Required Repeat Fixes


.contextual-background-fixes(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-header-color) {
  .column {
    border-color: @local-border-color;

  }
  h1, h2, h3, h4, h5, h6 {
    color: @local-header-color;
    span {
      //color: @local-link-color;
    }
  } p, li {
      color: @local-text-color;
    }
  i.fa, i.b-icon {
    color: @local-text-color !important;
  }
  div.text-object a {
    color: @local-link-color;
    &:hover {
      text-decoration: underline;
    }
  }

  ul, ol {
    li {
      a {
        color: @local-link-color;
      }
    }
    li:before {
      color: @local-text-color;
    }
  }
  hr {
    border-top-color: @local-border-color;
  }
  .column {
    border-color: @local-border-color;
  }

  .btn, input.gform_button {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    i, span.b-icon {
      color: inherit !important;
    }
    &.btn-default , &.button {
      color: @brand-text-on-dark;
      background-color: @local-link-color;
      border-color: @local-link-color;
      //mix-blend-mode: lighten;

      &:hover, &:focus {
        color: @brand-text-on-dark;
        background-color: fade(@local-link-color,70%);
        outline-color: @local-link-color;
        border-color: @local-link-color;

        &:before {
          color: @local-link-color;
        }
        &:after {
          color: @local-link-color;
        }
      }
    }
    &.btn-alternate {
      color: @text-color;
      background-color: transparent;
      border: 0px solid;
      border-radius: 0;
      border-bottom: @btn-alt-thickness solid fade(@local-link-color, 0%);
      text-decoration-color: @local-link-color;
      //mix-blend-mode: lighten;
      &:hover, &:focus {
        color: @text-color;
        background-color: transparent;
        border-bottom: @btn-alt-thickness solid fade(@local-link-color, 100%);
        text-decoration-thickness: .125em;
        outline-color: transparent;
        &:before {
          color: @local-text-color;
        }
        &:after {
          color: @local-text-color;
        }
      }
    }
  }
}

.headings-color-override(@local-headings-color){
  h1,h2,h3,h4,h5,h6 {
    color: @local-headings-color;
  }
}

// Global Background-mixins ----------------------------------------------------------------------------------------

// used to add bg color modifiers

.bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-header-color) {
  border-color: @local-border-color;
  background: @local-bg-color;
  color: @local-text-color;
  .contextual-background-fixes(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-header-color);

  // Use this code to override colors with contextual link color.
  //.headings-color-override(@local-link-color);
}


// bg-variants(@color; @text-color; @modifier;)
// color describes background color textcolor describes text color this modifies links as well as link styles.
// modifier is proposed manipulator to darken or lighten of hover styles all hard coded for right now.

.bg-white {
  @local-bg-color:            #fff;
  @local-text-color:          @brand-black;
  @local-link-color:          @brand-primary;
  @local-border-color:        @local-text-color;
  @local-header-color:        @brand-black;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);

}

.bg-black {
  @local-bg-color:            @brand-black;
  @local-text-color:          @brand-text-on-dark;
  @local-link-color:          @brand-text-on-dark;
  @local-border-color:        @local-text-color;
  @local-header-color:        @brand-text-on-dark;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);
}

.bg-gray-dark {
  @local-bg-color:            @brand-gray-dark;
  @local-text-color:          @brand-text-on-dark;
  @local-link-color:          @brand-primary;
  @local-border-color:        @local-text-color;
  @local-header-color:        @brand-text-on-dark;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);
}

.bg-gray {
  @local-bg-color:            @brand-gray;
  @local-text-color:          @brand-black;
  @local-link-color:          @brand-primary;
  @local-border-color:        @local-text-color;
  @local-header-color:        @brand-black;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);
}


.bg-gray-light {
  @local-bg-color:            @brand-gray-light;
  @local-text-color:          @brand-black;
  @local-link-color:          @brand-tertiary;
  @local-border-color:        @local-text-color;
  @local-header-color:        @brand-black;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);
}

.bg-primary {
  @local-bg-color:            @brand-primary;
  @local-text-color:          @brand-text-on-dark;
  @local-link-color:          @brand-black;
  @local-border-color:        @local-text-color;
  @local-header-color:        @brand-text-on-dark;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);
}

.bg-secondary {
  @local-bg-color:            @brand-secondary;
  @local-text-color:          #fff;
  @local-link-color:          #fff;
  @local-border-color:        @brand-gray-dark;
  @local-header-color:        #fff;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);
}

.bg-tertiary {
  @local-bg-color:            @brand-tertiary;
  @local-text-color:          @brand-text-on-dark;
  @local-link-color:          @brand-primary;
  @local-border-color:        @local-text-color;
  @local-header-color:        @brand-text-on-dark;

  .bg-variants(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);
}







// used to add support for mask style color overlays on images
.bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color) {
  z-index: 2;
  border-color: @local-border-color;
  color: @text-color;
  background-size: cover;
  background-position: center center;
  &:before {
    z-index: -1;
    position: absolute;
    background: fade(@local-overlay-color, @overlay-image-opacity);
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    left: 0;
    top: 0;

  }
  .contextual-background-fixes(@local-bg-color, @local-text-color, @local-link-color, @local-border-color,@local-header-color);

}

// Global Background-mixins  END ----------------------------------------------------------------------------------------

// BG MIXIN CALLS ----------------------------------------------------------------------------------------------------------

// bg-variants-image(@color; @text-color;)
// color is used to define background color overlay and text color is used to define inhereted text color.
.bg-image-dark {
  @local-overlay-color:     rgba(0,0,0,0);
  @local-bg-color:          @brand-black;
  @local-text-color:        @brand-text-on-dark;
  @local-link-color:        @brand-secondary;
  @local-border-color:      @brand-text-on-dark;
  @local-header-color:      @brand-text-on-dark;

  .bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color);
  &:before {
    display: none;
  }
}

.bg-image-dark-overlay {
  @local-overlay-color:     @brand-gray-dark;
  @local-bg-color:          @brand-gray-dark;
  @local-text-color:        @brand-text-on-dark;
  @local-link-color:        @brand-primary;
  @local-border-color:      @brand-text-on-dark;
  @local-header-color:      @brand-text-on-dark;

  .bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color);
 }

.bg-dark-overlay {
  @local-overlay-color:     @brand-gray-dark;
  @local-bg-color:          @brand-gray-dark;
  @local-text-color:        @brand-text-on-dark;
  @local-link-color:        @brand-primary;
  @local-border-color:      @brand-text-on-dark;
  @local-header-color:      @brand-text-on-dark;

  .bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color);

}



.bg-image-light {
  @local-overlay-color:     transparent;
  @local-bg-color:          @brand-text-on-dark;
  @local-text-color:        @brand-black;
  @local-link-color:        @brand-primary;
  @local-border-color:      @brand-gray-light;
  @local-header-color:      @brand-black;

  .bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color);
  &:before {
    display: none;
  }
}

.bg-image-light-overlay {
  @local-overlay-color:     @brand-text-on-dark;
  @local-bg-color:          @brand-text-on-dark;
  @local-text-color:        @brand-black;
  @local-link-color:        @brand-primary;
  @local-border-color:      @brand-gray-light;
  @local-header-color:      @brand-black;

  .bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color);
}

.bg-light-overlay {
  @local-overlay-color:     @brand-text-on-dark;
  @local-bg-color:          @brand-text-on-dark;
  @local-text-color:        @brand-black;
  @local-link-color:        @brand-primary;
  @local-border-color:      @brand-gray-light;
  @local-header-color:      @brand-black;

  .bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color);

}


.bg-image-primary-overlay {
  @local-overlay-color:     @brand-primary;
  @local-bg-color:          @brand-primary;
  @local-text-color:        @brand-text-on-dark;
  @local-link-color:        @brand-black;
  @local-border-color:      @brand-text-on-dark;
  @local-header-color:      @brand-text-on-dark;

  .bg-variants-image(@local-bg-color, @local-text-color, @local-link-color, @local-border-color, @local-overlay-color,@local-header-color);

}


//BACKGROUND MODIFIER CLASSES ----------------------------------------------------------------------------------------

.bg-center {
  background-position: center center !important;
}

.bg-repeat-vert {
  background-size: 100%;
  background-repeat: repeat-y;
}

.bg-cover {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-half-image-black {
  z-index: 1;
  position: relative;
  &:before, &:after {
    width: 50%;
    z-index: -1;
    display: block;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    background: transparent;
    left: 0;
  }
  &:after {
    background: @brand-black;
    right: 0;

  }

  @media ( max-width: @screen-sm-max) {
    .row .column ~ .column {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    &:before, &:after {
      width: 100%;
      height: 50%;
      z-index: -1;
      display: block;

      content: '';
      position: absolute;
      top: initial;
      left: 0;
    }

    &:after {
      background:@brand-black;
      top: 0px;
    }

    &:after {
      top: 50%;

    }

  }
  @media(max-width:@screen-xs-max) {
    &:before, &:after {
      display: none;
    }
  }
}

