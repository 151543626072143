// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border: @btn-border-width solid fade(@background,0%);
  transition: background @transition-time-global, border @transition-time-global;
  border-radius: @btn-border-radius-base;
  text-transform: uppercase;
  &:active,
  &.active,&:focus,
  &.focus ,&:hover,&:hover {
    //color: @color;
    //background-color:  darken(@background,5%) ;
    //    border-color: @background;
    color: @color;
    //background-color: darken(@background, 17%);
    background: fade(@background,70%);
    border-color: @border;



  }

  .open > .dropdown-toggle& {
    color: @background;
    background-color: darken(@background, 10%);
        border-color: darken(@border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: @background;
      background-color: darken(@background, 17%);
          border-color: darken(@border, 25%);
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  .pad-fixer(@button-correction);


  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}
